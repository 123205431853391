import React, {useContext, useState} from 'react';
import Layout from './Layout';
import FileInfoModal from './files/FileInfoModal';
import FileContentViewer from './files/FileContentViewer';
import FileTableList from './files/FileTableList';

import {fileDTO, fileURLModel} from '../models/fileModel';
import AuthenticationContext from './auth/AuthenticationContext';
import MUBbtn from './elements/MUBbtn';
import {getEmptyBlob} from '../utils/formDataUtils';
import SharedFileStateProvider from './files/SharedFileStateProvider';

import logoNoText from './../resources/logo-no-text.png'
import api, {endpoints} from '../utils/api';

import '../styles/Files.scss';

const FilesPage: React.FC<{ isFoundFilesPage?: boolean }> = () => {
    const {foundedFiles} = useContext(AuthenticationContext);

    const [blobURL, setBlobURL] = useState<fileURLModel>(getEmptyBlob());
    const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);

    // MUB logic
    const [MUBResult, setMUBResult] = useState('');
    const {textSearch} = useContext(AuthenticationContext);
    const [isMUBloading, setMUBloading] = useState(false);

    const performSearch = async () => {
        try {
            const response = await api.get(endpoints.files + '/llm-answer?search=' + textSearch);
            setMUBResult(response.data);
        } catch (error) {
            console.error('Search failed:', error);
        } finally {
            setMUBloading(false);
        }
    };

    const handleMUBClick = () => {
        setMUBloading(true);
        setMUBResult('');
        performSearch();
    };

    // Get img blob after Search for FileModal (preview)
    const getFileBlobHandler = async (fileId: string) => {
        const response = await api.get(endpoints.files + `/preview/${fileId}`, {responseType: "blob"})
        const blob = response.data;
        const fileURL = URL.createObjectURL(blob);
        setBlobURL({fileURL, type: blob.type, blob: blob});
        setIsFileViewerOpen(true);
    };

    const [openMenuId, setOpenMenuId] = useState<string | null>('');

    const toggleMenu = (menuId: string) => {
        setOpenMenuId(prevId => (prevId === menuId ? null : menuId));
    };

    // FileModal Logic
    const [fileToModal, setFileToModal] = useState<fileDTO | null>(null);

    const fileModalOpenHandler = (file: fileDTO) => {
        setFileToModal(file);
    };

    const onHideFileViewer = () => {
        setIsFileViewerOpen(false);
        setTimeout(() => setBlobURL(getEmptyBlob()), 500);
    };

    return (
        <Layout>
            <div className="MUB_Wrapper">
                <div className="mub-container">
                    <div className={`mub-thought ${isMUBloading || MUBResult ? 'isMUBloading' : ''}`}>
                        {!MUBResult ? <div className="dot-elastic"></div> :
                            <div className="mub-text">
                                {MUBResult}
                            </div>
                        }
                    </div>
                </div>
                <div className="MUB_btn_container">
                    <MUBbtn disabled={!textSearch || isMUBloading} onClick={handleMUBClick}/>
                </div>
            </div>

            <SharedFileStateProvider>
                <FileTableList
                    fileModalOpenHandler={fileModalOpenHandler}
                    toggleMenu={toggleMenu}
                    openMenuId={openMenuId}
                    foundedFiles={foundedFiles}
                    getFileBlobHandler={getFileBlobHandler}
                />
            </SharedFileStateProvider>

            {isFileViewerOpen && (
                <FileContentViewer
                    blobURL={blobURL}
                    onHide={onHideFileViewer}
                />
            )}

            <FileInfoModal
                fileToModal={fileToModal}
                show={!!fileToModal}
                onHide={() => setFileToModal(null)}
                centered
            />
        </Layout>
    );
};

export default FilesPage;
